<template>
    <ValidationObserver rules="required">
      <RightSideBar @close="$emit('close','audit-info')" button-class="bg-dynamicBackBtn text-white" :submit="'Manage Permissions'" submit-button-width="12rem" :cancel="'Cancel'" :loading="isLoading" @submit="openPermisssions">
        <template v-slot:title>
          <div class="text-darkPurple flex gap-5 items-center">
            <div class=""  @click="closeSidebar()"><icon icon-name="back_arrow" size="s" class="w-8 h-8 rounded text-romanSilver"/></div> Audit
          </div>
        </template>
        
        <div class="flex flex-col justify-center items-center gap-2 mt-20 w-full">
            <img :src="require('@/assets/images/audit.svg')" alt="not_found">
            <div class="bg-ghostWhite p-5 info w-full flex flex-col gap-5 text-sm" v-if="auditInfo.length > 0">
                <div class="w-full flex justify-between items-center" v-for="(audit, index) in auditInfo" :key="index">
                  <div class="flex flex-col justify-center gap-2">
                    <div class="text-base text-jet">{{ audit.user.fname}} {{ audit.user.lname}} {{ audit.audit.action}}</div>
                    <div class="text-romanSilver uppercase text-10">{{ $DATEFORMAT( new Date(audit.audit.createdAt), "MMMM dd, yyyy") }} at {{ $formatDateToTime( new Date(audit.audit.createdAt), "MMMM dd, yyyy") }}</div>
                  </div>
                  <div class="font-bold text-blueCrayola">View</div>
                </div>
            </div>
        </div>
      </RightSideBar>
    </ValidationObserver>
  </template>

  <script>
  import { ValidationObserver } from "vee-validate";
  import RightSideBar from "../RightSideBar";

  export default {
    name: "AuditInfo",
    components: {
      ValidationObserver,
      RightSideBar
    },
    props: {
        item: {
        type: Object,
        default: () => ({})
      },
      currentTab: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        isLoading: false,
        isOpen: true,
        auditInfo: [],
      };
    },
    methods: {
        closeSidebar() {
            this.$emit('close','audit-info');
        },
        openPermisssions() {
          this.$emit("open-permisssions");
        },
        async getFileAudit() {
            try {
                this.isLoading = true;
                const id = this.item.fileId
                
                const { data } = await this.$_getFileAuditInfo(id);
                this.isLoading = false;
                this.auditInfo = data.data
            } catch (error) {
              this.isLoading = false;
              throw new Error(error);
            }
        },
        async getFolderAudit() {
          try {
              this.isLoading = true;
              const id = this.item.folderId
              const { data } = await this.$_getFolderAuditInfo(id);
              this.isLoading = false;
              this.auditInfo = data.data
            } catch (error) {
              console.log(error, "error")
              this.isLoading = false;
              throw new Error(error);
          }
        },
    },
    async mounted() {
        this.currentTab.toLowerCase().includes('folders') && await this.getFolderAudit();
        !this.currentTab.toLowerCase().includes('folders') && await this.getFileAudit();
    }
  };
  </script>

  <style scoped>
    .info{
        border-radius: 12px;
    }
    .text-10{
      font-size: 10px
    }
  </style>
